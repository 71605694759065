
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    subNavigationItems() {
      return this.$store.getters['navigation/sub_navigation_items']
    },
  },

  methods: {
    getPage(cardId) {
      if (this.subNavigationItems) {
        return this.subNavigationItems.find((element) => element.id === cardId)
      }
      return []
    },
  },
}
