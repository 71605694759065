
export default {
  props: {
    card: {
      type: Object,
      required: true,
      default: () => {},
    },

    linkText: {
      type: String,
      default: 'Mehr anzeigen',
    },
  },
}
